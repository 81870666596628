import { useFetcher, useNavigate } from '@remix-run/react'
import { useEffect } from 'react'

import { useToast } from '~/components/Toast'
import Button from '~/components/Button'
import type { ButtonProps } from '~/components/Button'
import type { Plan } from '~/types/subscriptions'
import type action from './action'

interface ContinueToPaymentButtonProps extends Omit<ButtonProps, 'text'> {
  plan?: Plan
  subscriptionUserSeats?: number
  text?: string
  onComplete?: () => void
}

const ContinueToPaymentButton = ({
  text,
  intent = 'primary',
  plan,
  subscriptionUserSeats,
  onComplete,
  ...props
}: ContinueToPaymentButtonProps) => {
  const addToast = useToast()
  const navigate = useNavigate()
  const { data, submit, state } = useFetcher<typeof action>()
  const { success, errors, redirectUrl } = data || {}

  const navigateToStripe = () => {
    const formData = new FormData()
    if (plan) formData.append('plan', plan)
    if (subscriptionUserSeats) formData.append('subscriptionUserSeats', subscriptionUserSeats.toString())

    submit(formData, {
      action: '/api/account/signup/continue-to-payment',
      method: 'post'
    })
  }

  const isSubmitting = state === 'submitting' || state === 'loading'

  useEffect(() => {
    if (state === 'idle') {
      if (success) {
        // Call onComplete if the request was successful
        if (onComplete) onComplete()
        else if (redirectUrl) {
          if (redirectUrl.startsWith('http')) {
            window.location.href = redirectUrl
          } else {
            navigate(redirectUrl)
          }
        }
      } else if (success === false && errors?.length) {
        // Display errors as toasts
        errors.forEach(error => {
          addToast({
            title: error?.message || 'An error occurred',
            duration: 4000,
            intent: 'danger'
          })
        })
      }
    }
  }, [state, success, errors, navigate, redirectUrl, addToast, onComplete])

  return (
    <Button
      text={text || 'Continue to payment'}
      intent={intent}
      onClick={navigateToStripe}
      isLoading={isSubmitting}
      {...props}
    />
  )
}

export default ContinueToPaymentButton
